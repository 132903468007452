/* AboutMe.css */
.about-me-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
  }
  
  .profile-picture img {
    width: 300px; /* Increase the width as needed */
    height: 300px; /* Increase the height as needed to maintain the aspect ratio */
    border-radius: 50%; /* Keeps the image round */
    object-fit: cover; /* Ensures the image covers the area, good for maintaining aspect ratio */
    display: block;
    margin: 0 auto; /* Centers the image horizontally */
    /* Add more styles as needed */
  }
  
  .about-me-content h2,
  .about-me-content h3 {
    padding-left: 40px;
    color: #000000; /* Adjust the color to match the design */
  }
  
  .about-me-content ul {
    list-style: none; /* This removes the default list styling */
  }
  
  .about-me-content li {
    padding: 5px ; /* This adds some space between the list items */
    padding-left: 20px;
    text-indent: -27px;
    color: #000000; /* Adjust the color to match the design */
  }

.navbar {
  width: 100%; /* Full width */
  background-color: black; /* Black background */
  padding: 20px 0; /* Some padding on the top and bottom */
  display: flex;
  justify-content: center; /* Center the nav items horizontally */
  margin-bottom: -25px;
}

.nav-item {
  color: white; /* White text color */
  text-decoration: none; /* Remove underline from links */
  margin: 0 15px; /* Margin on the left and right for spacing */
  padding: 5px 10px; /* Padding for clickable area */
  border-bottom: 2px solid transparent; /* Transparent border for a hover effect */
}

.nav-item:hover {
  border-bottom: 2px solid white; /* White border on hover for an underline effect */
}

@media (max-width: 768px) {
  /* Styles for screens that are 768px wide or less */
  
  .about-me-container {
    flex-direction: column; /* Stack flex items vertically */
    padding: 10px; /* Reduce padding on smaller screens */
    width: auto;
  }
  
  .profile-picture img {
    width: 200px; /* Decrease the width for smaller screens */
    height: 200px; /* Decrease the height to maintain the aspect ratio */
    margin-top: 20px; /* Add some space above the image */
  }
  
  .about-me-content h2,
  .about-me-content h3,
  .about-me-content li {
    padding-left: 20px; /* Reduce the padding for smaller screens */
  }
  
  .about-me-content li {
    text-indent: -20px; /* Adjust text indent for smaller screens */
  }

  .navbar {
    flex-wrap: wrap; /* Allow nav items to wrap onto the next line if space is limited */
    padding: 10px 0; /* Reduce the top and bottom padding */
  }

  .nav-item {
    margin: 5px; /* Reduce margin for smaller screens */
    padding: 5px; /* Adjust padding for a comfortable tap target */
  }
}