/* In your main stylesheet, for example, App.css */

body {
    background-image: url('background_data.png');
    background-size: cover; /* This will ensure that the image covers the whole page */
    background-position: center; /* This will center your image */
    background-repeat: no-repeat; /* This will prevent the image from tiling */
  }
  
  
/* The white box style */
.main-box {
  width: 1000px; /* Adjust the width as necessary, 80% for example */
  padding: 2rem; /* Inner space of the white box */
  background-color: #fff; /* The white background color */
  border-radius: 10px; /* Rounded corners for the white box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle depth effect */
  margin-top: 20px; /* Pushes the box down from the top of the viewport */
  margin-bottom: 100px;
  /* Additional styles for your box */
}

/* The parent container style */
.parent-container { /* This should be the direct parent of .main-box */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Aligns children to the start, i.e., top of the container */
  min-height: 100vh;
  flex-direction: column;
  /* Remove any top padding or margin here if present */
}

/* Add your media queries to ensure responsiveness */
@media (max-width: 768px) {
  .main-box {
    width: 80%; /* On smaller screens, the box takes up more width */
  }
}
