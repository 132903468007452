.main-box {
  background-color: white;
  width: 50%; /* Set to desired width, not exceeding 100% */
  margin: auto; /* For horizontal centering if needed, though flexbox should handle it */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative; /* If you need to position children absolutely within */
  z-index: 2;
  /* Removed the `top` property because flexbox will center the box vertically */
}

/* Body styles to enable flexbox centering */
body {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh;
  margin: 0; /* Removes default margin */
  background-size: cover;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-box {
    width: 90%; /* Adjust width for smaller screens if needed */
  }
}
