/* Projects.css */

.projects-page-container {
    text-align: center;
    padding-top: 20px; /* Adjust to align with your page layout */
    margin-top: 0;
    width: 70%;
    margin: auto;
}

.projects-title {
    margin-bottom: 20px; /* Space between title and resume box */
    color: #fff;
}

.projects-content-box {
    background-color: white;
    padding: 20px;
    margin: auto; /* Centers the box */
    width: 96%; /* Adjust width as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for depth */
    border-radius: 5px; /* Optional: rounds the corners of the box */
    margin-top: 20px;
    margin-bottom: 50px;
}

.projects-header {
    margin: 0px;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Adjust the number of columns as needed */
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 20px; /* Adjust the gap between projects as needed */
}

.project {
  background: #f5f5f5; /* Change the color as needed */
  padding: 20px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add other styles as necessary */
}

.project a {
  display: block;
  color: #0066cc;
  text-decoration: none;
  /* Add other styles as necessary */
}

.project a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  /* Styles for screens that are 768px wide or less */
  
  .projects-page-container {
    padding-top: 10px;
    width: auto;
  }

  .projects-content-box {
    width: auto; /* Slightly smaller width to add some padding around the content */
    margin: 20px auto; /* Increased margin-top and margin-bottom to 20px and center the content */
  }

  .projects-container {
    display: grid;
    grid-template-columns: 1fr; /* Stack the projects in one column on smaller screens */
    grid-gap: 15px; /* Adjust the gap between projects as needed */
  }

  .project {
    padding: 15px; /* Slightly smaller padding */
  }

}
