.resume-page-container {
  text-align: center;
  padding-top: 20px; /* Adjust to align with your page layout */
  width: 70%;
  margin: auto;
}

.resume-title {
  margin-bottom: 20px; /* Space between title and resume box */
  color: #fff;
}

.resume-content-box {
  background-color: white;
  padding: 20px;
  margin: auto; /* Centers the box */
  width: 96%; /* Adjust width as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for depth */
  border-radius: 5px; /* Optional: rounds the corners of the box */
  margin-top: 20px;
  margin-bottom: 50px;
}

.download-resume-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #000; /* Button color */
  color: #fff; /* Text color */
  text-decoration: none; /* Removes underline */
  border-radius: 5px;
font-weight: bold;
}

.download-resume-button:hover {
background-color: #444; /* Darker color on hover */
}

.resume-section {
  text-align: left;
}

.skills-container {
  display: flex;
  justify-content: space-between;
}

.skills-list {
  column-count: 2;
  width: 100%; /* Each column will take up half the width of the container */
  margin-left: 140px;
  padding: 0; /* Remove default padding */
  column-gap: 10px;
}


.education-section {
  text-align: center; /* This centers the title */
}

.education-content {
text-align: left;
}

.education-content p {
text-align: left;
}

.programs-container {
  display: flex;
  justify-content: space-between;
}

.programs-list {
  column-count: 2;
  width: 100%; /* Each column will take up half the width of the container */
  margin-left: 130px;
  padding: 0; /* Remove default padding */
  column-gap: 10px;
}

@media (max-width: 768px) {
  /* Styles for screens that are 768px wide or less */
  
  .resume-page-container {
    padding-top: 10px;
    width: auto;
  }

  .resume-content-box, .education-content, .programs-list, .skills-list {
    width: auto; /* Slightly smaller width to add some padding around the content */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  .skills-container, .programs-container {
    flex-direction: column
  ; /* Stack the flex items vertically on smaller screens */
  }

  .skills-list {
  column-count: 1;
  margin-left: 100px; /* Only one column for smaller screens /
    padding: 0 10px; / Add padding inside the list */
  }

  .programs-list {
    column-count: 1;
    margin-left: 100px;
  }

  .download-resume-button {
  padding: 8px 16px; /* Slightly smaller padding /
  font-size: 0.9em; / Smaller font size */
  }}

  .summary-section {
    text-align: center;
  }