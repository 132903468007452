/* Navigation.css */

.navbar-resize {
    /* Example resizing properties */
    height: 600px;
    /* Other specific styles you want to apply to the navbar on the resume page */
  }
  
  /* Responsive adjustments with media queries */
@media (max-width: 768px) {
  .navbar-resize {
    height: auto; 
    width: auto;/* or a smaller fixed height, whatever looks best on mobile */
    /* Adjust other properties as needed for a mobile-friendly navbar */
  }
}
